define('ember-cp-validations/-private/ember-validator', ['exports', 'ember-cp-validations/validators/base', 'ember-validators'], function (exports, _emberCpValidationsValidatorsBase, _emberValidators) {
  var _slice = Array.prototype.slice;
  exports['default'] = _emberCpValidationsValidatorsBase['default'].extend({
    validate: function validate() {
      var result = _emberValidators.validate.apply(undefined, [this.get('_evType')].concat(_slice.call(arguments)));

      if (result && typeof result === 'object') {
        return this.createErrorMessage(result.type, result.value, result.context);
      }

      return result;
    }
  });
});