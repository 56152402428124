define('ember-cp-validations/validators/alias', ['exports', 'ember', 'ember-cp-validations/validators/base'], function (exports, _ember, _emberCpValidationsValidatorsBase) {
  var get = _ember['default'].get;
  var assert = _ember['default'].assert;
  var isPresent = _ember['default'].isPresent;
  var getProperties = _ember['default'].getProperties;

  /**
   *  <i class="fa fa-hand-o-right" aria-hidden="true"></i> [See All Options](#method_validate)
   *
   *  Creates an alias between a single attribute's validations to another.
   *  This copies all messages, errors, etc., to the current attribute as well as
   *  its validation state (isValid, isValidating, etc.)
   *
   *  ## Examples
   *
   *  ```javascript
   *  validator('alias', 'attribute')
   *  validator('alias', {
   *    alias: 'attribute',
   *    firstMessageOnly: true
   *  })
   *  ```
   *
   *  @class Alias
   *  @module Validators
   *  @extends Base
   */
  var Alias = _emberCpValidationsValidatorsBase['default'].extend({
    /**
     * Normalized options passed in.
     * ```js
     * validator('alias', 'attribute')
     * // Becomes
     * validator('alias', {
     *   alias: 'attribute'
     * })
     * ```
     *
     * @method buildOptions
     * @param  {Object}     options
     * @param  {Object}     defaultOptions
     * @param  {Object}     globalOptions
     * @return {Object}
     */
    buildOptions: function buildOptions() {
      var options = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];
      var defaultOptions = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];
      var globalOptions = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

      var opts = options;

      if (typeof options === 'string') {
        opts = {
          alias: options
        };
      }
      return this._super(opts, defaultOptions, globalOptions);
    },

    /**
     * @method validate
     * @param {Any} value
     * @param {Object} options
     * @param {String} options.alias The attribute to alias
     * @param {Boolean} options.firstMessageOnly If true, only returns the first error message of the
     *                                           aliased attribute and will not include validation state
     * @param {Object} model
     * @param {String} attribute
     */
    validate: function validate(value, options, model, attribute) {
      var _getProperties = getProperties(options, ['alias', 'firstMessageOnly']);

      var alias = _getProperties.alias;
      var firstMessageOnly = _getProperties.firstMessageOnly;

      assert('[validator:alias] [' + attribute + '] option \'alias\' is required', isPresent(alias));

      var aliasValidation = get(model, 'validations.attrs.' + alias);

      return firstMessageOnly ? get(aliasValidation, 'message') : get(aliasValidation, 'content');
    }
  });

  Alias.reopenClass({
    getDependentsFor: function getDependentsFor(attribute, options) {
      var alias = typeof options === 'string' ? options : get(options, 'alias');

      assert('[validator:alias] [' + attribute + '] \'alias\' must be a string', typeof alias === 'string');

      return [alias + '.messages.[]', alias + '.isTruelyValid'];
    }
  });

  exports['default'] = Alias;
});
/**
 * Copyright 2016, Yahoo! Inc.
 * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
 */