define('ember-task-scheduler/utils/wait-for', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = waitFor;

	var _slicedToArray = function () {
		function sliceIterator(arr, i) {
			var _arr = [];
			var _n = true;
			var _d = false;
			var _e = undefined;

			try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);

					if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;
				_e = err;
			} finally {
				try {
					if (!_n && _i["return"]) _i["return"]();
				} finally {
					if (_d) throw _e;
				}
			}

			return _arr;
		}

		return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError("Invalid attempt to destructure non-iterable instance");
			}
		};
	}();

	var isPresent = Ember.isPresent,
	    typeOf = Ember.typeOf;


	var defaultWait = 50;
	var defaultTimeout = 0;

	function runConditional(conditional) {
		try {
			var ret = conditional();

			return [null, ret];
		} catch (e) {
			return [e, null];
		}
	}

	function runTimeout(func, wait) {
		if (!wait) {
			requestAnimationFrame(func);
		} else {
			setTimeout(func, wait);
		}
	}

	/**
  * Wait for a condition to met.
  *
  * @method waitFor
  * @param  Function conditional
  * @param  Number wait
  * @return Ember.RSVP.Promise
  * @public
  */
	function waitFor(conditional) {
		var wait = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : defaultWait;
		var timeout = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : defaultTimeout;

		Ember.assert('First argument must be a function and return a boolean', conditional && typeOf(conditional) === 'function');
		Ember.assert('Second argument must be a number', isPresent(wait) && typeOf(wait) === 'number');

		return new Ember.RSVP.Promise(function (resolve, reject) {
			var cancel = false;

			function checkConditional() {
				if (cancel) {
					return;
				}

				var _runConditional = runConditional(conditional),
				    _runConditional2 = _slicedToArray(_runConditional, 2),
				    err = _runConditional2[0],
				    ret = _runConditional2[1];

				if (err) {
					reject(err);
				} else if (ret) {
					resolve(ret);
				} else {
					runTimeout(checkConditional, wait);
				}
			}

			runTimeout(checkConditional, wait);

			if (timeout) {
				setTimeout(function () {
					cancel = true;
					reject(new Error('wait for error: timeout.'));
				}, timeout);
			}
		});
	}
});