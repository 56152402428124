define('ember-cp-validations/validators/length', ['exports', 'ember', 'ember-cp-validations/-private/ember-validator'], function (exports, _ember, _emberCpValidationsPrivateEmberValidator) {
  var get = _ember['default'].get;
  var isNone = _ember['default'].isNone;

  /**
   *  <i class="fa fa-hand-o-right" aria-hidden="true"></i> [See All Options](#method_validate)
   *
   *  Validates the length of the attributes’ values.
   *
   *  ## Examples
   *
   *  ```javascript
   *  validator('length', {
   *    is: 15
   *  })
   *  validator('length', {
   *    min: 5,
   *    max: 10
   *  })
   *  ```
   *
   *  @class Length
   *  @module Validators
   *  @extends Base
   */
  exports['default'] = _emberCpValidationsPrivateEmberValidator['default'].extend({
    _evType: 'length',

    /**
     * Default allowNone to true
     *
     * @method buildOptions
     * @param  {Object}     options
     * @param  {Object}     defaultOptions
     * @param  {Object}     globalOptions
     * @return {Object}
     */
    buildOptions: function buildOptions() {
      var options = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];
      var defaultOptions = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];
      var globalOptions = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

      options.allowNone = isNone(get(options, 'allowNone')) ? true : get(options, 'allowNone');

      return this._super(options, defaultOptions, globalOptions);
    }
  });
});
/**
 * Copyright 2016, Yahoo! Inc.
 * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
 */